




























































































































































import api from "@/api/index"; //ABP API接口
import { Component, Vue } from "vue-property-decorator";
import PagedTableView from "@/components/PagedTableView.vue";
import {
  OrderMainActualOutStoragePostDto,
  OrderMainDto,
  OrderReceivingMethod,
  SelectListItem,
} from "@/api/appService";
import ActualOutStorage from "@/views/warehouse/warehouseExManagement/components/actualOutStorage.vue";

@Component({
  name: "WarehouseExManagementList",
  components: {
    ActualOutStorage,
    PagedTableView,
  },
})
export default class WarehouseExManagementList extends Vue {
  queryForm = {
    productNumber: "",
    orderCode: "",
    userName: "",
  };

  outStorageTypeList = [] as SelectListItem[];

  showActualOutStorage = false;
  currentReceivingMethod =
    OrderReceivingMethod.LogisticsDelivery as OrderReceivingMethod;
  actualOutStorageForm = {
    id: "",
    logisticsCompany: "",
    logisticsOrderNo: "",
    warehouseId: undefined,
  } as OrderMainActualOutStoragePostDto;

  created() {
    this.fetchOutStorageTypeList();
  }

  // 获取表数据
  fetchData(params: any) {
    console.log("test:" + params);
    return api.orderMain.getAll({ ...params });
  }

  async fetchOutStorageTypeList() {
    await api.enumService
      .getValues({ typeName: "OutStorageType" })
      .then((res) => {
        this.outStorageTypeList = res;
      });
  }

  getOutStorageTypeText(value: any) {
    let text = "";
    for (let i in this.outStorageTypeList) {
      if (this.outStorageTypeList[i].value === value) {
        text = this.outStorageTypeList[i].text!;
      }
    }
    return text;
  }

  jumpDetail(index: number, row: OrderMainDto) {
    this.$router.push({ name: "invoice", params: { id: row.id!.toString() } });
  }

  handleImmediateDelivery(row: OrderMainDto) {
    this.currentReceivingMethod = row.receivingMethod as OrderReceivingMethod;
    this.actualOutStorageForm = {
      id: row.id,
    };
    this.showActualOutStorage = true;
  }
}
