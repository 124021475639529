



















































































import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";
import api from "@/api";
import {
  OrderMainActualOutStoragePostDto,
  OrderReceivingMethod,
  WarehouseDto,
} from "@/api/appService";
import { ElForm } from "element-ui/types/form";

@Component({
  name: "actualOutStorage",
})
export default class actualOutStorage extends Vue {
  @Ref() readonly dataForm!: ElForm;

  @Prop({ required: true })
  show!: boolean;

  @Prop({ required: true })
  receivingMethod!: OrderReceivingMethod;

  @Prop({ required: true })
  form!: OrderMainActualOutStoragePostDto;

  formData = {} as OrderMainActualOutStoragePostDto;

  wareHouseList: WarehouseDto[] = [];

  created() {
    this.fetchWareHouseList();
  }
  async fetchWareHouseList() {
    await api.wareHouse.getAll({ maxResultCount: 65535 }).then((res: any) => {
      this.wareHouseList = res!.items;
    });
  }

  @Watch("show")
  onShowChange(newVal: boolean) {
    if (newVal) {
      Object.assign(this.formData, this.form);
    }
    this.$nextTick(() => {
      this.dataForm.clearValidate();
    });
  }

  roleRule = {
    warehouseId: [
      {
        required: true,
        message: (this as any).$l.getLanguageText(
          "warehouseInStorageMain.tips.currentWarehouse"
        ),
        trigger: "blur",
      },
    ],
  };

  save() {
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        await api.orderMain
          .actualOutStorage({
            body: this.formData,
          })
          .then(() => {
            this.$message.success(
              (this as any).$l.getLanguageText(
                "orderManagement.orderHasBeenShipped"
              ) as string
            );
            this.show = false;
            setTimeout(() => {
              (this as any).$bus.$emit("data-updated");
            }, 2000);
          })
          .catch((err) => {
            this.$message.error(err);
          });
      }
    });
  }

  cancel() {
    this.$emit("update:show", false);
  }
}
